import { useState } from 'react';
import hero from './images/photodune-4leZfb1R-three-little-corellas-on-a-branch-xl-comp.jpg';

interface ClickableProps {
  className?: string,
  [x: string]: any,
}
const Clickable = (props: ClickableProps) => {
  return (
    <button
      className={`text-gray-800 hover:text-green-700 underline ${props.className}`}
      {...props} />
  );
};

interface LinkProps extends ClickableProps {
  to: string,
}
const Link = ({ to, ...props }: LinkProps) => {
  return <a href={to}><Clickable {...props} /></a>;
};

interface BasicProps {
  children: string | JSX.Element | Array<string | JSX.Element>,
  className?: string,
}
const Para = ({ className, children }: BasicProps) => {
  return <p className={`my-4 ${className}`}>{children}</p>;
};

const Section = ({ className, children }: BasicProps) => {
  return (
    <div
      className={`px-8 rounded-xl text-lg backdrop-filter backdrop-blur ${className}`}
      style={{
        backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4))`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "60% 10%",
        maxWidth: 600,
      }}>
      {children}
    </div>
  )
}

function App() {
  let [showEmail, setShowEmail] = useState(false);
  const user = "oliver"
  const domain = "oliverrobson"
  const tld = "tech"
  let email = "";
  if (showEmail) {
    email = `${user}@${domain}.${tld}`
  }

  return (
    <div
      className="min-h-screen flex flex-col px-8 sm:px-20 lg:px-24 xl:px-28 py-6 sm:py-16 lg:py-12 xl:py-16 relative"
      style={{
        backgroundColor: "#487ca4",
        backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), url(${hero})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "60% 10%",
      }}>
      <h1 className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl xl:text-8xl font-logo text-gray-200">
        Corella Creations
      </h1>
      <Section className="mt-8 xl:mt-24 py-8">
        <Para>Corella Creations is a Melbourne-based tech startup founded in 2021. Their current focus is on Web Application Development.</Para>
        <Para>The main project underway is <Link to="https://proto-tracker.com">Proto Tracker</Link>, a webapp for aiding tracking the status and location of your prototypes.</Para>
        <Para>{!showEmail
          ? <>If you would like to contact Corella Creations, <Clickable onClick={() => setShowEmail(true)}>click here</Clickable> to show their email.</>
          : <>You can contact Corella Creations at: <Link to={`mailto:${email}`}>{email}</Link></>}
        </Para>
      </Section>
      <div className="flex-grow" />
      <Section className="mt-8">
        <Para className="">© Corella Creations 2021</Para>
      </Section>
    </div>
  );
}

export default App;
